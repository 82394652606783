<template>
  <div>
    <Card class="d-flex" :title="'Plano de Fundo'">
      <div
        v-if="backgroundUrl == ''"
        class="
          align-self-center
          py-3
          d-flex
          align-items-center
          justify-content-center
          flex-column
          thumb-container
        "
        slot="card-body"
      >
        <fa-icon
          icon="exclamation-circle"
          class="text-warning icon-style"
        ></fa-icon>
        <p class="text-muted">Nenhum plano de fundo selecionado</p>
      </div>
      <div v-else class="thumb-container-bg py-3" slot="card-body">
        <img :src="backgroundUrl" class="thumbnail-bg" />
      </div>
      <hr />
      <div class="pb-3" slot="card-footer">
        <hr />
        <div class="d-flex justify-content-end">
          <b-button @click="open_slider" variant="success">Selecionar</b-button>
        </div>
      </div>
    </Card>
    <SweetModal
      ref="background_modal"
      overlay-theme="dark"
      width="80%"
      hide-close-button
    >
      <template #title>
        <span class="text-muted">Selecione o plano de fundo</span>
      </template>
      <template #default style="overflow-x: hidden !important">
        <div style="width: 100%">
          <slider ref="slider" :options="options" @slide="slideIndex" class="p-2">
            <slideritem
              v-for="background in backgrounds"
              :key="background.id"
              :style="{ width: '25%', 'margin-right': '2%' }"
            >
              <Card style="width: 100%" class="d-flex align-items-center" :class="[background.id == value?'border border-info':'']">
                <template #card-body>
                  <input
                    type="radio"
                    @change="check"
                    :checked="background.id == value"
                    :value="background.id"
                    class="check-img mt-2"
                  />

                  <div class="thumb-container py-3">
                    <img :src="background.url" class="thumbnail" />
                  </div>
                </template>

                
              </Card>
            </slideritem>
          </slider>
        </div>

        <div class="d-flex justify-content-center mt-2" style="width: 78vw">
          <b-button
            @click="$refs.slider.$emit('slidePre')"
            pill
            class="mr-2"
            variant="outline-info"
            ><fa-icon icon="arrow-left"
          /></b-button>
          <b-button
            @click="$refs.slider.$emit('slideNext')"
            pill
            class="mr-2"
            variant="outline-info"
          >
            <fa-icon icon="arrow-right"
          /></b-button>
        </div>
      </template>

      <template #button>
        <b-button class="mr-2" @click="$refs.uploadImage.open()"> Novo Fundo </b-button>
        <b-button
          @click="$refs.background_modal.close()"          
          
        >
          Fechar</b-button
        >
        
      </template>
    </SweetModal>
    <SweetModal
      ref="uploadImage"
      overlay-theme="dark"
      hide-close-button
      blocking
    >
      <template #title>
        <span>Enviar novo Fundo</span>
      </template>

      <vue-file-agent
        v-if="send.status == ''"
        ref="fileUpload"
        :helpText="'Clique para enviar novo plano de fundo'"
        :multiple="false"
        v-model="loadedFiles"
      ></vue-file-agent>
      <div
        v-if="send.status !== ''"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <sweetalert-icon v-if="send.status == 'sending'" icon="loading" />
        <sweetalert-icon v-if="send.status == 'error'" icon="error" />
        <sweetalert-icon v-if="send.status == 'success'" icon="success" />
        <span class="lead">{{ send.msg }}</span>
      </div>
      <template v-if="send.status == ''" #button>
        <b-button class="mr-2" @click="$refs.uploadImage.close()"
          >Cancelar</b-button
        >
        <b-button @click="upload_image()">Enviar</b-button>
      </template>
      <template v-else #button>
        <b-button class="mr-2" @click="done()">Fechar</b-button>
      </template>
    </SweetModal>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  props: {
    checked: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      send: {
        status: "",
        msg: "Aguarde Enviando...",
      },
      loadedFiles: [],
      backgroundUrl: "",
      backgroundChecked: [],
      options: {
        itemAnimation: true,
        loopedSlides: 1,
        slidesToScroll: 1,
        thresholdDistance: 200,
        pagination: false,
        currentPage: 0,
      },
      isActive: "isActive",
    };
  },
  components: {
    slider,
    slideritem,
    Card: () => import("@/components/cards/Card"),
  },
  watch: {
    value: {
      handler(v) {
        if (v) {
          const checked = _.filter(this.backgrounds, (bg) => {
            return bg.id == v;
          });
          this.backgroundUrl = checked[0].url;
        }
      },
      immediate: true,
    },
  },
  computed: {
    backgrounds() {
      return this.$store.state.templates.backgroundsList;
    },
  },
  methods: {
    async upload_image() {
      if (this.loadedFiles.length > 0) {
        this.send.status = "sending";
        this.send.msg = "Aguarde Enviando...";
        const formData = new FormData();
        formData.append("file", this.loadedFiles[0].file);
        const send = await this.$store.dispatch(
          "templates/upload_background",
          formData
        );
        if (!send.error) {
          this.send.status = "success";
          this.send.msg = "Plano de fundo enviado com sucesso!";
        } else {
          this.send.status = "error";
          this.send.msg = send.error;
        }
      } else {
        this.send.status = "error";
        this.send.msg = "Selecione um arquivo válido";
      }
    },
    done() {
      this.$refs.uploadImage.close();
      this.send.status = "";
      this.loadedFiles = [];
    },
    check(event) {
      this.$emit("input", event.target.value);
    },
    open_slider() {
      this.$refs.background_modal.open();
    },
    slideIndex(idx) {
      this.options.currentPage = idx.currentPage;
    },
    salvar() {
      const bgSelected = _.filter(this.backgrounds, (b) => {
        return b.id == this.backgroundChecked[0];
      });
      this.$emit("selectedBackground", bgSelected[0]);
    },
  },
  async mounted() {
    // const backgrounds = await this.$store.dispatch(
    //   "templates_default/loadBackgrounds"
    // );
    // this.backgrounds = backgrounds;
    // if (this.checked) {
    //   this.backgroundChecked.push(this.checked);
    //   this.salvar();
    // }
  },
};
</script>

<style lang="scss" scoped>
.icon-style {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.thumbnail {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.thumb-container {
  width: 100%;
  height: 30vh;
}
.check-img {
  width: 25px;
  height: 25px;
  margin-right: 10px !important;
}
.isActive {
  border: 1px solid #007bff;
}
.thumb-container-bg {
  align-self: center;
  width: 16vw;
  height: 30vh;
}
.thumbnail-bg {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
::v-deep .sweet-modal {
  overflow-x: hidden !important;
}
</style>